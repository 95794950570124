<template>
  <form
      @submit.prevent="submitPurchase"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getSalesOrderNo"  back-to="sales">

      <!-- Current Step Buttons -->
      <div v-for="button in currentStepButtons" :key="button.label" class="inline-block">
        <AppButton
            :color="button.color"
            :label="button.label"
            class="ml-2"
            @click="button.action"
            :icon="!!button.icon"
        >
          <template v-slot:icon v-if="button.icon">
            <Icon :iconType="button.icon" class="mr-2"/>
          </template>
        </AppButton>
      </div>

    </page-header>


    <main class="pr-6 pl-6   flex-1 overflow-y-scroll">
      <div class="flex flex-col md:flex-row items-stretch justify-between mt-6">
        <!-- Main Column 1 -->
        <div class="flex-1 bg-white p-4 rounded md:rounded-none mr-6 flex flex-col justify-between">
          <div>
            <div class="flex items-center mb-2">
              <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                Sold To <span class="text-red-400">*</span>
              </h3>
              <hr class="flex-1 border-primary ml-4"/>
            </div>
            <div>
              <ContactAutocomplete
                  class="w-full"
                  v-model="sold_to"
                  @contactSelected="handleSoldToSelected"
                  @contactCleared="handleSoldToCleared"
                  :forceSelection="true"/>
            </div>
            <div class="w-full flex">
              <ContactInfoCard :contact="sold_to"></ContactInfoCard>
            </div>
          </div>
          <div class="flex justify-end mt-4">
            <AppOutlinedButton
                label="Add New"
                color="indigo"
                @click="addNewContact"
                :icon=true
            >
              <template v-slot:icon>
                <Icon iconType="add" class="mr-2"/>
              </template>
            </AppOutlinedButton>
          </div>
        </div>

        <!-- Main Column 2 -->
        <div class="flex-1 bg-white p-4 rounded md:rounded-none mr-6 flex flex-col justify-between">
          <div>
            <div class="flex items-center mb-2">
              <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                Invoice To <span class="text-red-400">*</span>
              </h3>
              <hr class="flex-1 border-primary ml-4"/>
            </div>
            <div>
              <ContactAutocomplete
                  class="w-full"
                  v-model="invoice_to"
                  @contactSelected="handleInvoiceToSelected"
                  @contactCleared="handleInvoiceToCleared"
                  :forceSelection="true"/>
            </div>
            <div class="w-full flex">
              <ContactInfoCard :contact="invoice_to"></ContactInfoCard>
            </div>
          </div>
          <div class="flex justify-end mt-4">

          </div>
        </div>

        <!-- Main Column 3 -->
        <div class="flex-1 bg-white p-4 rounded md:rounded-none mr-6 flex flex-col justify-between">
          <div>
            <div class="flex items-center mb-2">
              <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
                Delivery To<span class="text-red-400">*</span>
              </h3>
              <hr class="flex-1 border-primary ml-4"/>
            </div>
            <div>
              <ContactAutocomplete
                  class="w-full"
                  v-model="delivery_to"
                  @contactSelected="handleDeliveryToSelected"
                  @contactCleared="handleDeliveryToCleared"
                  :forceSelection="true"/>
            </div>
            <div class="w-full flex">
              <ContactInfoCard :contact="delivery_to"></ContactInfoCard>
            </div>
          </div>
          <div class="flex justify-end mt-4">

          </div>
        </div>

        <!-- Main Column 3 -->
        <div class="flex-2 bg-white p-4 rounded md:rounded-none">
          <div class="flex items-center mb-2">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Set Details
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <div class="grid grid-cols-3 gap-2">
            <!-- First Main Column (66%) -->
            <div class="col-span-2 grid grid-cols-2 gap-2">
              <!-- First Sub-Column (50%) -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Order Date <span class="text-red-400">*</span></label>
                <Calendar
                    v-model="sales.order_date"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <!-- Second Sub-Column (50%) -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Requested Delivery Date <span class="text-red-400">*</span></label>
                <Calendar
                    v-model="sales.requested_delivery_date"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <!-- Order Notes (full width) -->
              <div class="col-span-2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Order Notes</label>
                <textarea
                    v-model="sales.notes"
                    class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
                    placeholder="Note..."
                    rows="3"
                ></textarea>
              </div>
              <!-- Internal Notes (full width) -->
              <div class="col-span-2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Internal Notes</label>
                <textarea
                    v-model="sales.internal_notes"
                    class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
                    placeholder="Note..."
                    rows="3"
                ></textarea>
              </div>
            </div>
            <!-- Second Main Column (33%) -->
            <div class="col-span-1 flex flex-col gap-2">
              <!-- Supplier Reference -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Customer PO</label>
                <input
                    v-model="sales.customer_po_number"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                />
              </div>
              <!-- Order Currency -->
              <div class="flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Order Type<span class="text-red-400">*</span></label>
                <select v-model="sales.order_type" class="bge-input bge-input-spacing rounded">
                  <option value="SPARE PARTS">SPARE PARTS</option>
                  <option value="QUOTATION">QUOTATION</option>
                  <option value="INT JOBSHEET">INT JOBSHEET</option>
                  <option value="EXT JOBSHEET">EXT JOBSHEET</option>
                  <option value="EQUIPMENT">EQUIPMENT</option>
                  <option value="WARRANTY">WARRANTY</option>
                  <option value="FOC">FOC</option>
                </select>
              </div>
              <!-- Requested By -->
<!--              <div class="flex flex-col px-5 mb-3">-->
<!--                <label class="uppercase font-semibold text-sm">Requested By</label>-->
<!--                <input-->
<!--                    v-model="sales.requested_by"-->
<!--                    class="bge-input bge-input-spacing rounded"-->
<!--                    type="text"-->
<!--                />-->
<!--              </div>-->
<!--              &lt;!&ndash; Prepared By &ndash;&gt;-->
<!--              <div class="flex flex-col px-5 mb-3">-->
<!--                <label class="uppercase font-semibold text-sm">Created By<span class="text-red-400">*</span></label>-->
<!--                <StaffAutocomplete v-model="sales.created_by"/>-->
<!--              </div>-->
            </div>
          </div>
        </div>


      </div>

      <!-- Select Products -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-2">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Select Products
            </h3>
            <hr class="flex-1 border-primary ml-4"/>

            <AppButton v-if="sales.status === 'open' || sales.status === 'items added'" color="indigo"
                       label="Add Product" @click="showAddProduct"
                       :icon=true class="ml-4">
              <template v-slot:icon>
                <Icon iconType="add" class="mr-2"/>
              </template>
            </AppButton>

          </div>
          <OrderItems :sales_order_id="sales_order_id" ref="orderItemRef" @changed="handleOrderItemChanged"
                      :initialProducts="sales.order_items"></OrderItems>
        </div>
      </div>


      <!--Order Cost -->
      <div class="w-full bg-white mt-6  p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <ProductSummary
              v-if="sales"
              :products="getOrderItems()"
              :initialDuty="sales.duty"
              :initialShippingCost="sales.shipping_cost"
              :initialShippingTaxCode="sales.shipping_tax_code"
              :initialShippingTaxRate="sales.shipping_tax_rate"
              @update-duty="updatePurchaseValue('duty', $event)"
              @update-shipping-cost="updatePurchaseValue('shipping_cost', $event)"
              @update-shipping-tax-code="updatePurchaseValue('shipping_tax_code', $event)"
              @update-shipping-tax-rate="updatePurchaseValue('shipping_tax_rate', $event)"
          />


        </div>
      </div>

      <!-- Dispatch Notes -->
      <div class="w-full bg-white mt-6 p-5 pt-1 mb-6 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-2">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Despatch Notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>

          </div>
          <DispatchItems :initialSalesOrder="sales"></DispatchItems>
        </div>
      </div>

    </main>

    <portal to="overlay-outlet">
      <PDFViewer :showing="pdfViewerData.isShowingViewer" :pdfBlob="pdfViewerData.pdfData"
                 :documentName="pdfViewerData.documentName" @close="closeViewer"/>
    </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="addProductPanelOpen"
          @close="addProductPanelOpen = false"
          title="Add Product"
      >
        <AddProduct
            @complete="applyProductDetails"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="sendEmailPanelOpen"
          @close="sendEmailPanelOpen = false"
          title="Send Email"
      >
        <SendEmail v-if="sales" :salesOrderId="sales.sales_order_id"
                   :salesOrderNumber="sales.sales_order_no"
                   :customer="sold_to" :salesOrder="sales"/>
      </panel>
    </portal>
  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import LoanEquipmentAutoComplete from '@/components/AutoComplete/LoanEquipmentAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import AppOutlinedButton from '@/components/OutlinedButton.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import WorkCarried from "@/views/HR/Engineer/JobSheet/Components/WorkCarried.vue";
import PartsUsed from "@/views/HR/Engineer/JobSheet/Components/PartsUsed.vue";
import Products from "@/views/HR/Engineer/JobSheet/Components/Products.vue";
import LoanEquipmentList from "@/views/HR/Engineer/JobSheet/Components/LoanEquipmentList.vue";
import ManageFiles from "@/views/HR/Engineer/JobSheet/Components/ManageFiles.vue";
import ManageQuotationFiles from "@/views/HR/Quotation/Components/ManageFiles.vue";
import Signature from "@/components/Signature.vue";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
import ContactInfoCard from "@/components/Contacts/InfoCard.vue";
import OrderItems from "@/views/HR/Sales/Components/OrderItems.vue";
import AddProduct from "@/views/HR/Sales/Components/AddProduct.vue";
import ProductSummary from "@/views/HR/Sales/Components/ProductSummary.vue";
import SendEmail from "@/views/HR/Sales/Components/SendEmail.vue";
import printJS from "print-js";
import FindEquipment from "@/views/HR/Engineer/Certificate/Components/FindEquipment.vue";
import DispatchItems from "@/views/HR/Sales/Components/DispatchItems.vue";

const Toggle = () => import("@/components/Toggle.vue");
const PDFViewer = () => import("@/components/PDFViewer.vue");
export default {
  name: "ManagePurchase",
  mixins: [notificationMixin],
  components: {
    DispatchItems,
    FindEquipment,
    StaffAutocomplete,
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    LoanEquipmentAutoComplete,
    AppButton,
    Icon,
    Toggle,
    WorkCarried,
    PartsUsed,
    Products,
    LoanEquipmentList,
    ManageFiles,
    Signature,
    ManageQuotationFiles,
    ContactInfoCard,
    OrderItems,
    AddProduct,
    ProductSummary,
    PDFViewer,
    SendEmail,
    AppOutlinedButton,
  },
  props: {
    sales_order_id: String,
  },
  data() {
    return {
      isBusy: false,
      addProductPanelOpen: false,
      sales: {
        order_date:null,
        requested_delivery_date:null,
        order_type:null,
        order_items: [],
        duty: 0.0,
        shipping_cost: 0.0,
        shipping_tax_code: "",
        shipping_tax_rate: 0.0,
        supplier_reference: '',
      },
      products: [],
      tax_codes: [],
      pdfViewerData: {
        documentName: "",
        pdfData: null,
        isShowingViewer: false,
      },
      sendEmailPanelOpen: false,
      productSearchTermInput: '',
      productSearchTerm: '',
      productCodeSearchInput: '',
      selectedLoanEquipment: null,
      selectedLoanEquipmentAction: null,
      loanEquipmentActionList: [
        {id: 1, name: "Left On Site", short_name: "Left"},
        {id: 2, name: "Collected From Site", short_name: "Collected"}
      ],
      jobSheetCompletionActionList: [
        {id: "yes", name: "YES – JOB COMPLETED",},
        {id: "no", name: "NO – JOB COMPLETED",},
        {id: "incomplete", name: "JOB INCOMPLETE – FURTHER WORK REQUIRED",},
      ],
      selectedJobSheetCompletionAction: null,
      equipmentMakeList: [
        {id: "BOSTON", name: "BOSTON"},
        {id: "BRADBURY", name: "BRADBURY"},
        {id: "CONSUL", name: "CONSUL"},
        {id: "CRYPTON", name: "CRYPTON"},
        {id: "EAE", name: "EAE"},
        {id: "KISMET", name: "KISMET"},
        {id: "LAYCOCK", name: "LAYCOCK"},
        {id: "MAHLE", name: "MAHLE"},
        {id: "NUSBAUM", name: "NUSBAUM"},
        {id: "RAVAGLIOLI", name: "RAVAGLIOLI"},
        {id: "SNAP-ON / SUN", name: "SNAP-ON / SUN"},
        {id: "TECALEMIT", name: "TECALEMIT"},
        {id: "WERTHER", name: "WERTHER"}
      ],
      selectedEquipmentMake: null,
      assignedLoanEquipment: [],
      job_sheet: {
        job_start_date: null,
        job_start_time: null,
        is_internal: true,
        is_external: false,
        is_warranty: false,
      },
      schedule: {},
      sold_to: null,
      invoice_to: null,
      delivery_to: null,
      columns: [
        {
          field: "equipment.product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '8%',
        },
        {
          field: "equipment.name",
          header: "Description",
          sortable: true,
          custom: false,
          width: '17%',
        },
        {
          field: "equipment.serial_number",
          header: "Serial No.",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "equipment.next_calibration",
          header: "Calibration",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-calibration",
          width: '10%',
        },
        {
          field: "equipment.next_service",
          header: "Service",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-service",
          width: '10%',
        },
        {
          field: "equipment.warranty_expiry",
          header: "Expiry",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-warranty",
          width: '10%',
        },
        {
          field: "scheduleDetail.is_install",
          header: "Install",
          custom: true,
          slotName: "job-slot-install",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_repair",
          header: "Repair",
          custom: true,
          slotName: "job-slot-repair",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_service",
          header: "Service",
          custom: true,
          slotName: "job-slot-service",
          width: '7%',
        },
        {
          field: "scheduleDetail.calibration",
          header: "Calibration",
          custom: true,
          slotName: "job-slot-calibration",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "Warranty",
          custom: true,
          slotName: "job-slot-warranty",
          width: '7%',
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "",
          custom: true,
          slotName: "job-slot-sheet",
          width: '3%',
        },


      ],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },
      workCarriedOut: [],
      partsUsed: [],
      selectedPreSetText: null,
      pre_set_text: [],
      columnsWorkCarriedOut: [
        {field: 'pre_text_id', header: 'ID'},
        {field: 'text', header: 'Text'},
      ]
    };
  },
  async mounted() {
    this.isBusy = true;

    if (!this.sales_order_id) {
      if (this.$store.state.user && this.$store.state.user.staff_id) {
        this.setDefaultCreatedBy()
      }
      this.setDefaultDates();
      this.isBusy = false;
      return;
    }

    if (this.sales_order_id) {
      await this.loadSalesAndApply();
      return;
    }

    this.setDefaultMake(this.job_sheet);

    this.isBusy = false;

  },
  watch: {
    // Watch for changes in route parameters
    '$route.params.sales_order_id': {
      handler: 'loadSalesAndApply',
      immediate: true
    }
  },
  computed: {

    pageTitle() {
      return this.sales_order_id ? "Update Sales Order" : "Create Sales Order";
    },

    getSalesOrderNo() {
      return this.sales && this.sales.sales_order_no ? this.sales.sales_order_no : "";
    },

    currentStepButtons() {
      const stepButtons = {
        'open': [
          {label: 'Save Order', color: 'blue', action: this.saveSales, icon: 'save'},
        ],
        'items added': [
          {label: 'Save Order', color: 'blue', action: this.saveSales, icon: 'save'},
          {label: 'Send to Despatch', color: 'indigo', action: this.createDispatch, icon: 'truck-delivery'},
          {label: 'Email Confirmation', color: 'green', action: this.sendEmail, icon: 'mail'},
          {label: 'Preview Confirmation', color: 'green', action: this.printSalesOrder, icon: 'print'},
        ],
        'confirmed': [
          {label: 'Save Order', color: 'blue', action: this.saveSales, icon: 'save'},
          {label: 'Email Confirmation', color: 'green', action: this.sendEmail, icon: 'mail'},
          {label: 'Preview Confirmation', color: 'green', action: this.printSalesOrder, icon: 'print'},
        ],
        'part dispatched': [
          {label: 'Save Order', color: 'blue', action: this.saveSales, icon: 'save'},
          {label: 'Email Confirmation', color: 'green', action: this.sendEmail, icon: 'mail'},
          {label: 'Preview Confirmation', color: 'green', action: this.printSalesOrder, icon: 'print'},
        ],
        'completed': [
          {label: 'Save Order', color: 'blue', action: this.saveSales, icon: 'save'},
          {label: 'Preview Confirmation', color: 'green', action: this.printSalesOrder, icon: 'print'},
        ],
      };

      return stepButtons[this.sales.status] || [
        {label: 'Save Order', color: 'blue', action: this.saveSales, icon: 'save'},
      ];
    },
  },
  methods: {

    async loadSalesAndApply() {
      this.isBusy = true;
      const sales = await this.loadSalesBySalesOrderId();
      this.sales = await this.formatSalesData(sales);
      this.isBusy = false;
    },

    async handleOrderItemChanged() {
      await this.loadSalesAndApply();
    },

    updatePurchaseValue(field, value) {

      this.sales[field] = value;
    },
    async setAllReceivedItems() {
      const confirmation = await this.confirmAction({
        message: 'Do you want to set all received items?',
        header: 'Confirm Action',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        await this.$refs.orderItemRef.setAllReceivedItems();
      }
    },

    async setApproved() {
      const confirmation = await this.confirmAction({
        message: 'Do you want to approve this order?',
        header: 'Confirm Approval',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        this.isBusy = true;
        try {
          const approvedDate = this.$moment().unix();

          const updateData = {
            status: 'approved',
            approved_date: approvedDate,
          };

          if (this.sales_order_id) {
            await this.PurchaseOrderService.updatePurchaseOrder(
                this.sales_order_id,
                updateData,
            );
            this.notifySuccess("Purchase order approved successfully");
            this.$router.go(-1);
          } else {
            this.notifyError("Purchase order ID is missing");
          }

        } catch (error) {
          this.notifyError("Couldn't approve the sales order");
          console.error(error);
        } finally {
          this.isBusy = false;
        }
      }
    },

    sendEmail() {
      this.sendEmailPanelOpen = true;
    },

    async addNewContact() {

      await this.$router.push({
        name: 'contacts',
      });
    },

    showAddProduct() {
      this.addProductPanelOpen = true;
    },
    async applyProductDetails(product) {
      await this.$refs.orderItemRef.addProducts([product]);
      await this.loadSalesAndApply();
    },
    async uploadSignature(files, refId) {
      return this.JobSheetService.uploadDocuments(files, refId);
    },
    async getSignature(refId, category) {
      return this.JobSheetService.getJobSheetDocuments(refId, category);
    },
    jobType() {
      if (this.job_id || this.job_sheet.job_id) {
        return 'external';
      } else {
        if (this.job_sheet.is_external) {
          return 'external';
        } else if (this.job_sheet.is_internal) {
          return 'internal';
        } else {
          return 'internal';
        }
      }
    },
    setDefaultDates() {

      if (!this.sales.order_date) {
        this.sales.order_date = this.$moment().toDate();
      }
      if (!this.sales.requested_delivery_date) {
        this.sales.requested_delivery_date = this.$moment().add(3, 'days').toDate();
      }
    },

    setDefaultCreatedBy() {
      this.sales.created_by = {
        name: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
        staff_id: this.$store.state.user.staff_id
      };
    },

    setDefaultMake(formattedJobSheet) {

      if (!formattedJobSheet.make) {
        const make = this.equipmentMakeList.find(make => make.id === "BOSTON");
        this.selectedEquipmentMake = make;
      }
    },

    convertSalesOrderDatesFromUnix(sales) {
      const convertUnixToDate = (unixTimestamp) => {
        return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
      };

      // Convert order_date
      if (sales.order_date) {
        sales.order_date = convertUnixToDate(sales.order_date);
      }

      // Convert required_delivery_date
      if (sales.requested_delivery_date) {
        sales.requested_delivery_date = convertUnixToDate(sales.requested_delivery_date);
      }

      return sales;
    },

    async fetchDataMasterTaxCodes() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=PO_Tax_Code&is_active=true&orderBy=name&order=1');
        this.tax_codes = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: item.value || '',
        }));
      } catch (error) {
        console.error('Error fetching data master certificates:', error);
      }
    },

    async formatSalesData(sales) {
      let formattedSales = JSON.parse(JSON.stringify(sales));

      // Formatting dates
      formattedSales = this.convertSalesOrderDatesFromUnix(formattedSales);

      function findDefault(items) {
        return items && items.length > 0 ? items.find(item => item.is_default) || null : null;
      }

      // Handle sold to information
      if (formattedSales.sold_to_contact && formattedSales.sold_to_contact.contact_id && formattedSales.sold_to_contact.name) {
        this.sold_to = {
          name: formattedSales.sold_to_contact.name,
          contact_id: formattedSales.sold_to_contact.contact_id
        };

        this.sold_to.address = findDefault(formattedSales.sold_to_contact.addresses);

        this.sold_to.people = findDefault(formattedSales.sold_to_contact.people);
      }

      // Handle invoice to information
      if (formattedSales.invoice_to_contact && formattedSales.invoice_to_contact.contact_id && formattedSales.invoice_to_contact.name) {
        this.invoice_to = {
          name: formattedSales.invoice_to_contact.name,
          contact_id: formattedSales.invoice_to_contact.contact_id
        };

        this.invoice_to.address = findDefault(formattedSales.invoice_to_contact.addresses);

        this.invoice_to.people = findDefault(formattedSales.invoice_to_contact.people);
      }

      // Handle delivery to information
      if (formattedSales.delivery_to_contact && formattedSales.delivery_to_contact.contact_id && formattedSales.delivery_to_contact.name) {
        this.delivery_to = {
          name: formattedSales.delivery_to_contact.name,
          contact_id: formattedSales.delivery_to_contact.contact_id
        };

        this.delivery_to.address = findDefault(formattedSales.delivery_to_contact.addresses);

        this.delivery_to.people = findDefault(formattedSales.delivery_to_contact.people);
      }

      formattedSales.order_items = await this.convertToOrderItems(formattedSales.items);

      return formattedSales;
    },

    async convertToOrderItems(dbProducts) {

      if (!dbProducts || dbProducts.length === 0) {
        return [];
      }

      await this.fetchDataMasterTaxCodes();

      return dbProducts.map(product => {
        return {
          product_id: product.product_id,
          sales_item_id: product.sales_item_id,
          product_code: product.product_code,
          supplier_product_code: product.supplier_product_code,
          quantity: product.quantity.toString(),
          price: product.unit_price,
          tax_code: product.tax_code,
          tax_rate: product.tax_rate,
          total: product.total,
          total_with_tax: product.total_with_tax,
          description: product.description,
          tax_codes: this.tax_codes,
          received_items: product.received_items || []
        };
      });
    },

    triggerProductSearch() {

      this.productSearchTerm = this.productSearchTermInput;
    },

    async triggerProductCodeSearch() {
      try {
        const product = await this.getProduct(this.productCodeSearchInput);

        this.pre_set_text = (product && Array.isArray(product.service_template)) ?
            product.service_template.reduce((acc, template) => {
              if (template.template_id && template.description) {
                acc.push({
                  pre_text_id: template.template_id,
                  text: template.description,
                });
              }
              return acc;
            }, []) : [];
      } catch (error) {
        this.notifyError("Error transforming product templates");
        console.error(error);
      }
    },

    async getProduct(searchTerm = '') {
      try {
        return await this.ProductService.getProductByCode(searchTerm);
      } catch (error) {
        this.notifyError("Error loading products");
        console.error(error);
      }
    },


    handleSoldToSelected: async function (selectedCustomer) {
      // Access the actual data from the value prop of selectedCustomer
      const customerData = selectedCustomer.value;

      if (customerData && customerData.agent_id) {
        try {
          const response = await this.ContactService.searchContactsWithType({ contact_id: customerData.agent_id });

          if (response && response.length > 0) {
            const assignedAgent = response[0];
            // Set INVOICE TO as the assigned agent
            this.invoice_to = _.cloneDeep(assignedAgent);

            // Set DELIVERY TO as SOLD TO (same as customerData)
            this.delivery_to = _.cloneDeep(customerData);
          }
        } catch (error) {
          console.error("Error fetching agent details: ", error);
        }
      } else {
        // If SOLD TO customer has no assigned agent
        this.invoice_to = _.cloneDeep(customerData); // Set INVOICE TO same as SOLD TO
        this.delivery_to = _.cloneDeep(customerData); // Set DELIVERY TO same as SOLD TO
      }
    },


    handleSoldToCleared(selectedContact) {
      this.sold_to = null;
      this.invoice_to = null;
      this.delivery_to = null;
    },

    handleInvoiceToSelected: function (selectedCustomer) {

    },

    handleInvoiceToCleared(selectedContact) {

    },

    handleDeliveryToSelected: function (selectedCustomer) {

    },

    handleDeliveryToCleared(selectedContact) {

    },



    handleLoanEquipmentActionSelected(item) {
      if (this.selectedLoanEquipment && this.selectedLoanEquipmentAction) {
        const equipmentWithAction = {
          ...this.selectedLoanEquipment,
          action: this.selectedLoanEquipmentAction.short_name
        };
        if (this.$refs.loanEquipmentListRef.addEquipment(equipmentWithAction)) {
          this.selectedLoanEquipment = null;
          this.selectedLoanEquipmentAction = null;
        }
      }
    },
    handleProductSelected(product) {
      this.$refs.partsUsedRef.addProducts([product]);
    },
    handleEquipmentCleared() {
      this.selectedLoanEquipmentAction = null;
    },

    onNotesAdded() {
      this.selectedPreSetText = null;
    },
    copyToWorkCarriedOut() {
      this.$refs.workCarriedOutRef.addItems(this.selectedPreSetText);
    },

    async fetchAndUpdateContactDetails(contact_id) {
      try {
        const response = await this.ContactService.searchContactsWithType({contact_id});
        if (response && response.length > 0) {
          this.updateCustomerDetails(response[0]);
          if (!this.schedule.customer) {
            this.schedule.customer = response[0];
          }
        } else {
          console.error('No contact details found for the given ID');
        }
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    handleEquipmentSelected: function (selectedEquipment) {

    },

    updateCustomerDetails: function (selectedCustomer) {

      this.customer.address = this.formatDefaultAddress(selectedCustomer);
      this.customer.people = selectedCustomer.people;
      this.loadComments(selectedCustomer.contact_id);
    },

    formatDefaultAddress: function (selectedCustomer) {
      if (!selectedCustomer || !selectedCustomer.address) {
        return ``;
      }

      let address = selectedCustomer.address;

      return [
        address.address1,
        address.address2,
        address.address3,
        address.city,
        address.postcode,
      ]
          .filter((part) => !!part)
          .join(", ");
    },

    async loadSchedule(scheduleId) {
      try {
        const scheduleData = await this.EquipmentScheduleService.getScheduleById(scheduleId);
        this.schedule = this.formatScheduleData(scheduleData);
      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    async loadSalesBySalesOrderId() {
      try {
        const salesData = await this.SalesOrderService.getSalesOrderById(this.sales_order_id);

        if (!salesData) {
          return;
        }
        return salesData;

      } catch (error) {
        this.isBusy = false;
        console.error("Error loading sales:", error);
      }
    },

    async loadScheduleByJobId(jobId) {
      try {
        const scheduleData = await this.EquipmentScheduleService.getScheduleByJobId(jobId);

        if (!scheduleData) {
          return;
        }
        this.job_sheet = {
          ...this.job_sheet,
          customer: scheduleData.customer,
          engineer: scheduleData.engineer,
          job_id: scheduleData.job_id
        };

      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },
    async loadEquipment(equipmentId) {
      try {

        const equipmentData = await this.EquipmentService.getEquipmentItemById(equipmentId);

        if (!equipmentData) {
          return;
        }


        this.job_sheet = {
          ...this.job_sheet,
          product_code: equipmentData.product_code,
          product_type: equipmentData.product_type,
          serial_number: equipmentData.serial_number,
          name: equipmentData.name,
          agent_name: equipmentData.service_agent ? equipmentData.service_agent.name : "",
          customer: {
            name: equipmentData.owner ? equipmentData.owner.name : "",
            contact_id: equipmentData.owner ? equipmentData.owner.contact_id : ""
          },
          equipment_id: equipmentData.equipment_id
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    async loadProduct(productCode) {
      try {

        const productData = await this.ProductService.getProductByCode(productCode);

        if (!productData) {
          return;
        }

        this.job_sheet = {
          ...this.job_sheet,
          product_code: productData.product_code,
          product_type: productData.type,
          name: productData.name,
        };

      } catch (error) {
        console.error("Error loading equipment:", error);
      }
    },

    loadComments: function (contact_id) {
      try {
        this.CommentService.getCommentByResourceIdAndTypeId(contact_id, "contact")
            .then(result => {

              console.log("[Comments] (mounted) comments:", result.data);
              const comments = this.getFirstCommentContent(result.data);
              this.$set(this.customer, 'comments', comments);
            })
            .catch(error => {
              console.error("Error fetching comments:", error);
            });
      } catch (error) {
        console.error(error);
      } finally {

      }
    },

    getFirstCommentContent: function (comments) {
      if (comments && comments.length > 0) {
        return comments[0].content;
      }
      return "No Notes";
    },

    formatScheduleData(schedule) {

      let formattedSchedule = JSON.parse(JSON.stringify(schedule));
      let momentObj = this.$moment.unix(formattedSchedule.scheduled_date);
      formattedSchedule.scheduled_date = momentObj.toDate();
      formattedSchedule.scheduled_time = new Date(`1970-01-01T${momentObj.format('HH:mm:ss')}`);

      return formattedSchedule;
    },

    convertSalesOrderDatesToUnix(sales) {
      const convertDateToUnix = (date) => {
        if (date instanceof Date) {
          return Math.floor(date.getTime() / 1000);
        } else if (typeof date === 'string') {
          const momentDate = this.$moment(date, 'DD-MM-YYYY', true);
          if (momentDate.isValid()) {
            return momentDate.unix();
          } else {
            const isoMomentDate = this.$moment(date, this.$moment.ISO_8601);
            if (isoMomentDate.isValid()) {
              return isoMomentDate.unix();
            }
          }
        }
        console.error('Invalid or unsupported date format:', date);
        return null;
      };

      // Convert order_date
      if (sales.order_date) {
        sales.order_date = convertDateToUnix(sales.order_date);
      }

      // Convert requested_delivery_date
      if (sales.requested_delivery_date) {
        sales.requested_delivery_date = convertDateToUnix(sales.requested_delivery_date);
      }

      return sales;
    },


    convertProductsForDB() {
      const products = this.getOrderItems();
      return products.map(product => {
        return {
          product_id: product.product_id,
          product_code: product.product_code,
          supplier_product_code: product.supplier_product_code,
          quantity: Number(product.quantity),
          unit_price: Number(product.price),
          tax_code: product.tax_code,
          tax_rate: product.tax_rate,
          total: Number(product.total),
          total_with_tax: Number(product.total_with_tax),
          description: product.description,
          received_items: product.received_items,
        };
      });
    },

    getAllSalesData(sales) {

      let convertedSales = JSON.parse(JSON.stringify(sales));

      // convertedPurchase.items = this.convertProductsForDB();
      convertedSales = this.convertSalesOrderDatesToUnix(convertedSales);

      if (this.sold_to && this.sold_to.contact_id) {
        convertedSales.sold_to = this.sold_to.contact_id;
      }
      if (this.invoice_to && this.invoice_to.contact_id) {
        convertedSales.invoice_to = this.invoice_to.contact_id;
      }
      if (this.delivery_to && this.delivery_to.contact_id) {
        convertedSales.delivery_to = this.delivery_to.contact_id;
      }

      convertedSales.duty = parseFloat(this.sales.duty) || 0;
      convertedSales.shipping_cost = parseFloat(this.sales.shipping_cost) || 0;
      convertedSales.shipping_tax_code = this.sales.shipping_tax_code;
      convertedSales.shipping_tax_rate = parseFloat(this.sales.shipping_tax_rate) || 0;

      // Remove dispatch_notes property
      delete convertedSales.dispatch_notes;

      return convertedSales;
    },

    getOrderItems() {
      return this.$refs.orderItemRef ? this.$refs.orderItemRef.getCurrentProducts() : [];
    },

    closeViewer() {
      this.pdfViewerData = {
        pdfData: null,
        documentName: "",
        isShowingViewer: false,
      };
    },

    async printSalesOrder() {
      if (!this.sales_order_id) {
        this.notifyWarning("Missing sales order ID. Please ensure all required fields are filled.");
        return;
      }

      try {
        const response = await this.SalesOrderService.generateSalesOrderReport(this.sales_order_id, 'sales-order');

        this.pdfViewerData = {
          pdfData: response,
          documentName: 'Sales Order Report',
          isShowingViewer: true,
        };
      } catch (error) {
        console.error('Error loading PDF:', error);
        this.notifyWarning("Failed to load the PDF document. Please try again.");
      }
    },


    getLoanEquipmentsData() {
      return this.$refs.loanEquipmentListRef ? this.$refs.loanEquipmentListRef.getCurrentLoanEquipments() : null;
    },

    combineAndFormatToUnix(schedule) {

      let dateString, timeString;

      if (typeof schedule.scheduled_date === 'string') {
        dateString = schedule.scheduled_date;
      } else {
        dateString = this.$moment(schedule.scheduled_date).format('DD-MM-YYYY');
      }

      if (typeof schedule.scheduled_time === 'string') {
        timeString = schedule.scheduled_time;
      } else {
        timeString = this.$moment(schedule.scheduled_time).format('HH:mm');
      }

      const dateTimeString = `${dateString} ${timeString}`;

      const unixTimestamp = this.$moment(dateTimeString, 'DD-MM-YYYY HH:mm').unix();

      let combinedSchedule = JSON.parse(JSON.stringify(schedule));
      combinedSchedule.scheduled_date = unixTimestamp;

      if (schedule.customer && schedule.customer.contact_id) {
        combinedSchedule.customer_id = schedule.customer.contact_id;
      }

      if (schedule.engineer && schedule.engineer.staff_id) {
        combinedSchedule.engineer_id = schedule.engineer.staff_id;
      }

      return combinedSchedule;
    },

    onRowClick(event) {

      this.Edit(event.data);
    },


    validateRequiredFields() {
      const requiredFields = [
        { field: this.sold_to, name: 'Sold To' },
        { field: this.invoice_to, name: 'Invoice To' },
        { field: this.sales.order_type, name: 'Order Type' },
        { field: this.sales.order_date, name: 'Order Date' },
        { field: this.sales.requested_delivery_date, name: 'Requested Delivery Date' },
      ];

      for (const field of requiredFields) {
        if (!field.field) {
          this.notifyError(`${field.name} is required.`);
          return false;
        }
      }
      return true;
    },


    createDispatch: async function () {

      this.isBusy = true;
      try {

        if (this.sales_order_id) {
          await this.SalesOrderService.createDispatchNote(
              this.sales_order_id,
              {},
          );
          this.notifySuccess("Despatch note created successfully");

          await this.loadSalesAndApply();
        } else {
          this.notifyError("Couldn't create despatch note now");
        }

      } catch (error) {
        let errorMessage = "Couldn't create despatch note";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },

    saveSales: async function () {

      if (!this.validateRequiredFields()) {
        return;
      }


      this.isBusy = true;
      const formattedSales = this.getAllSalesData(this.sales);
      try {

        if (this.sales_order_id) {
          await this.SalesOrderService.updateSalesOrder(
              this.sales_order_id,
              formattedSales,
          );
          this.notifySuccess("Sales order updated successfully");

          await this.loadSalesAndApply();
        } else {
          const response = await this.SalesOrderService.createSalesOrder(formattedSales);
          this.sales_order_id = response.sales_order_id;
          await this.$router.push({
            name: 'sales-edit',
            params: {sales_order_id: this.sales_order_id}
          });

          this.notifySuccess("Purchase created successfully");
        }

      } catch (error) {
        let errorMessage = this.sales_order_id
            ? "Couldn't update Sales Order"
            : "Couldn't create Sales Order";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitPurchase: async function (event) {
      event.preventDefault();
    },

    replacePlaceholders: function (htmlTemplate, dynamicData) {
      let htmlContent = htmlTemplate;
      Object.keys(dynamicData).forEach(key => {
        const value = dynamicData[key];
        const regex = new RegExp(`{{${key}}}`, "g");
        htmlContent = htmlContent.replace(regex, value);
      });
      return htmlContent;
    },
    dynamicValues: function (jobSheet) {

      const date = jobSheet.job_start_date ? this.$moment(jobSheet.job_start_date).format('DD/MM/YYYY') : 'N/A';
      const timeStart = jobSheet.job_start_date ? this.$moment(jobSheet.job_start_date).format('HH:mm') : 'N/A';
      const timeFinish = jobSheet.job_end_date ? this.$moment(jobSheet.job_end_date).format('HH:mm') : 'N/A';


      const workCarriedOutText = jobSheet.work_carried_out && jobSheet.work_carried_out.map(item => item.text ? item.text : '').join("\n") || '';
      const partsUsedText = jobSheet.parts_used && jobSheet.parts_used.map(part => part.name ? `${part.name} - Qty: ${part.quantity}` : '').join("\n") || '';
      const loanEquipmentText = jobSheet.loan_equipment && jobSheet.loan_equipment.map(equipment => equipment.name ? `${equipment.name}, Serial Number: ${equipment.serial_number}, Action: ${equipment.action}` : '').join("\n") || '';

      const notes = (jobSheet.notes || '').replace(/<[^>]+>/g, '');
      const jobDescription = (jobSheet.job_description || '').replace(/<[^>]+>/g, '');

      const internalChecked = jobSheet.type === 'internal' ? 'checked' : '';
      const externalChecked = jobSheet.type === 'external' ? 'checked' : '';
      const warrantyChecked = jobSheet.is_warranty ? 'checked' : '';

      const checkboxesHtml = `
        <label><input type="checkbox" ${internalChecked}> INTERNAL</label>
        <label><input type="checkbox" ${externalChecked}> EXTERNAL</label>
        <label><input type="checkbox" ${warrantyChecked}> WARRANTY</label>
        `;

      let loanEquipmentHtml = '';
      if (jobSheet.loan_equipment) {
        jobSheet.loan_equipment.forEach(equipment => {
          loanEquipmentHtml += `
         <tr>
               <td>${equipment.serial_number || ''}</td>
               <td>${equipment.name || ''}</td>
               <td>${equipment.action || ''}</td>
         </tr>
        `;
        });
      }

      let workCarriedOutHtml = '';
      if (jobSheet.work_carried_out) {
        jobSheet.work_carried_out.forEach(item => {
          workCarriedOutHtml += `<li>${item.text || ''}</li>\n`;
        });
      }

      let partsUsedHtml = '';
      if (jobSheet.parts_used) {
        jobSheet.parts_used.forEach(part => {
          partsUsedHtml += `
        <tr>
            <td>${part.product_code || ''}</td>
            <td>${part.quantity || ''}</td>
            <td>${part.name || ''}</td>
        </tr>
        `;
        });
      }

      const equipmentMake = jobSheet.make || '';
      const equipmentModel = jobSheet.equipment && jobSheet.equipment.name ? jobSheet.equipment.name : '';
      const equipmentDescription = jobSheet.equipment && jobSheet.equipment.product_type ? jobSheet.equipment.product_type : '';
      const equipmentSerialNumber = jobSheet.equipment && jobSheet.equipment.serial_number ? jobSheet.equipment.serial_number : '';
      let customerPostCode = 'N/A';
      if (jobSheet.customer && jobSheet.customer.addresses) {
        const defaultAddress = jobSheet.customer.addresses.find(address => address.is_default);
        if (defaultAddress) customerPostCode = defaultAddress.postcode || 'N/A';
      }
      const engineerName = jobSheet.engineer ? `${jobSheet.engineer.first_name || ''} ${jobSheet.engineer.last_name || ''}` : '';

      return {
        date: date,
        timeStart: timeStart,
        timeFinish: timeFinish,
        jobSheetNo: jobSheet.job_sheet_no || '',
        workCarriedOut: workCarriedOutText,
        partsUsed: partsUsedText,
        loanEquipment: loanEquipmentText,
        customerName: jobSheet.customer ? jobSheet.customer.name : 'N/A',
        engineerName: engineerName,
        notes: notes,
        signingCustomerName: jobSheet.signing_customer_name || '',
        checkboxesHtml: checkboxesHtml,
        loanEquipmentHtml: loanEquipmentHtml,
        workCarriedOutHtml: workCarriedOutHtml,
        partsUsedHtml: partsUsedHtml,
        equipmentMake: equipmentMake,
        equipmentModel: equipmentModel,
        equipmentDescription: equipmentDescription,
        equipmentSerialNumber: equipmentSerialNumber,
        customerPostcode: customerPostCode,
        customerContract: '',
        descriptionOfWork: jobDescription,
        furtherDetails: '',
        scheduledJobNo: jobSheet.job_id || '-',
        documentsAttached: '',
        customerSignature: '',
      };
    },


    async loadHtmlTemplate() {
      try {
        const response = await fetch("/report_job_sheet.html");
        if (!response.ok) {
          throw new Error("Failed to load report template");
        }
        return await response.text();
      } catch (error) {
        console.error("Error loading HTML template:", error);
        throw error; // Or handle it as needed
      }
    },

    async generateCustomerSignatureHtml() {

      const customerFiles = await this.JobSheetService.getJobSheetDocuments(this.job_sheet.job_sheet_id, 'signature');
      let customerSignatureHtml = '';

      if (customerFiles && customerFiles.length > 0) {
        const customerSignatureUrl = customerFiles[0].access_url;

        customerSignatureHtml = `
      <div class="content-wrapped">
        <img src="${customerSignatureUrl}" alt="Customer Signature" class="signature-image" />
      </div>
    `;
      } else {
        customerSignatureHtml = '<div class="content-wrapped"></div>';
      }

      return customerSignatureHtml;
    },

    async printJobSheet() {
      try {
        if (!this.job_sheet || !this.job_sheet.job_sheet_id) {
          this.notifyWarning("Please save the job sheet before attempting to print.");
        }
        const htmlTemplate = await this.loadHtmlTemplate();
        const dynamicData = this.dynamicValues(this.job_sheet);

        dynamicData.customerSignature = await this.generateCustomerSignatureHtml();


        const printContent = this.replacePlaceholders(htmlTemplate, dynamicData);

        console.log(printContent);

//         const html = `<div>Hello, <b>world!</b></div>`;
//
// // Directly convert the HTML string to a Base64-encoded string
//         const bytes = new TextEncoder().encode(printContent); // Convert the string to Uint8Array
//         const base64 = btoa(String.fromCharCode(...bytes)); // Convert bytes to Base64
//         console.log(base64);
//         const base64Html = 'your_base64_encoded_html_string_here';
//         const apiUrl = 'https://localhost:44377/api/pdfgen';
//         await this.postHtmlGetPdf(apiUrl, base64);

        // Assuming printJS is available and configured
        printJS({
          printable: printContent,
          type: "raw-html",
        });
      } catch (error) {
        console.error("Error printing vehicle record:", error);
      }
    },
    async postHtmlGetPdf(url, base64Html) {
      const headers = new Headers({
        'Content-Type': 'application/json'
      });

      const body = JSON.stringify({
        bodyHtml: base64Html // Ensure your API expects it in this format
      });

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: body
        });

        if (response.ok) {
          console.log('Request successful, handling PDF download...');
          const blob = await response.blob();
          this.downloadPdf(blob, "downloaded_pdf.pdf");
        } else {
          console.error('Failed to fetch PDF:', response.status);
          const errorResponse = await response.text();
          throw new Error(errorResponse);
        }
      } catch (error) {
        console.error('Error in fetching PDF:', error);
      }
    },

    downloadPdf(blob, filename) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    },
  }
}
;
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

</style>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

</style>