<template>
  <div class="card">
    <DataTable :value="dispatches" dataKey="dispatch_note_id">
      <!-- Dynamic columns -->
      <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header"
              :headerStyle="getHeaderStyle(col)" :styles="{ 'text-align': col.align }">

        <template v-if="col.field === 'requested_delivery_date'" #body="slotProps">
          {{ formatTimestamp(slotProps.data[col.field]) }}
        </template>

        <template v-if="col.field === 'dispatch_date'" #body="slotProps">
          {{ formatTimestamp(slotProps.data[col.field]) }}
        </template>

        <template  v-if="col.field === 'status'" #body="slotProps"">
          {{ checkStatusAndReturnUppercase(slotProps.data[col.field]) }}
        </template>

        <!-- Custom content based on column type -->
        <template v-else-if="col.type === 'actions'" #body="slotProps">
          <div class="flex justify-end items-center">
            <AppButton color="blue" label="" :icon="true" class="mr-1"
                       @click="editRow(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
          </div>
        </template>

        <!-- Default content for other columns -->
        <template v-else #body="slotProps">
          {{ slotProps.data[col.field] }}
        </template>

      </Column>

      <template #empty>
        No Items.
      </template>

    </DataTable>

  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";


export default {
  mixins: [notificationMixin],
  components: {
    AppButton,
    Icon
  },
  props: {
    initialSalesOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dispatches: [],
      columns: [
        {field: 'sales_order_no', header: 'Order Number',  width: '10%'},
        {field: 'dispatch_note_no', header: 'Despatch Number', width: '10%'},
        {
          field: 'delivery_to',  header: 'Delivery To',  width: '10%',
        },
        {
          field: 'requested_delivery_date', type:'date', header: 'Requested Date', width: '10%',
        },
        {
          field: 'dispatch_date', type:'date',header: 'Despatch Date', width: '10%',
        },
        {
          field: 'status', header: 'Status',   slotName: "status-slot", width: '10%',
        },
        {header: '', type: 'actions', width: '10%'}
      ],
    };
  },
  watch: {
    initialSalesOrder(newOrder) {
      this.dispatches = this.convertSalesOrderToDispatches(newOrder);
    },
  },
  async mounted() {
    this.dispatches = this.convertSalesOrderToDispatches(this.initialSalesOrder);
  },
  methods: {
    convertSalesOrderToDispatches(salesOrder) {
      if (!salesOrder || !salesOrder.dispatch_notes) return [];

      return salesOrder.dispatch_notes.map((dispatchNote) => {
        return {
          sales_order_no: salesOrder.sales_order_no,
          dispatch_note_no: dispatchNote.dispatch_note_no,
          delivery_to: this.getContactNameById(salesOrder.delivery_to_contact),
          requested_delivery_date: salesOrder.requested_delivery_date,
          dispatch_date: dispatchNote.dispatch_date,
          status: dispatchNote.status || "not packed",
          dispatch_note_id: dispatchNote.dispatch_note_id,
          sales_order_id: salesOrder.sales_order_id,
        };
      });
    },

    async editRow(rowData) {
      await this.$router.push({
        name: 'dispatch',
        params: {
          sales_order_id: rowData.sales_order_id,
          dispatch_note_id: rowData.dispatch_note_id,
        }
      });
    },

    getHeaderStyle(col) {
      return {
        width: col.width,
        textAlign: col['header-align'] || 'left',
      };
    },
    getContactNameById(contact) {
      return (contact && contact.name) ? contact.name : "Unknown";
    },
    formatTimestamp(unixTimestamp) {

      if (!unixTimestamp) {
        return '';
      }
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    checkStatusAndReturnUppercase(status) {
      if (status && status.toLowerCase() === 'items added') {
        return 'PENDING';
      }
      return status ? status.toUpperCase() : 'N/A';
    },
  }
};
</script>

<style scoped>
.total-amount {
  margin-top: 10px;
  font-weight: bold;
}

::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
